// src/components/Achievements.jsx

import React from 'react';
import './Achievements.css'; // Import the CSS file for styling
import img from '../components/img/q.jpg'; // Import the main image
import award1 from '../components/img/award1.jpg'; // Import additional images for the gallery
import award2 from '../components/img/award2.jpg';
import award3 from '../components/img/award3.jpg';

function Achievements() {
  return (
    <div className="achievements">
      <h1 className="achievements-title">Achievements</h1>
      <div className="achievements-content container">
        <div className="row align-items-center">
          <div className="col-lg-5 text-center">
            <img src={img} alt="Achievements" className="achievements-image img-fluid" />
          </div>
          <div className="col-lg-7">
            <div className="achievements-text">
              <h2 className="achievements-subtitle">Restcamp Kalibari</h2>
              <p className="achievements-description">
                Restcamp Kalibari has been recognized for its outstanding contributions to the community and
                spiritual activities. Over the years, we have been honored with numerous awards and medals
                for our participation in various events and festivals.
              </p>
              <ul className="achievements-list">
                <li>Rotary Club of Guwahati Smart City</li>
                <li>Excellence in Spiritual Leadership</li>
                <li>Medical Camp Programs</li>
              </ul>
            </div>
          </div>
        </div>

        <h2 className="gallery-title">Award Gallery</h2>
        <div className="gallery row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src={award1} alt="Award 1" className="gallery-image img-fluid" />
            <img src={img} alt="Achievements" className="gallery-image img-fluid" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src={award2} alt="Award 2" className="gallery-image img-fluid" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <img src={award3} alt="Award 3" className="gallery-image img-fluid" />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Achievements;
