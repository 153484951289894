// src/components/Gallery.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap'; // Importing necessary components from react-bootstrap
import './Gallery.css'; // Importing custom styles

// Sample images for the gallery
import image2023a from '../components/img/aa.jpg';
import image2023b from '../components/img/bb.jpg';
import image2023c from '../components/img/cc.jpg';

import image2022a from '../components/img/a.jpg';
import image2022b from '../components/img/b.jpg';
import image2022c from '../components/img/c.jpg';
import image2022d from '../components/img/3.jpg';
import image2022e from '../components/img/4.jpg';
import image2022f from '../components/img/7.jpg';


import image2024a from '../components/img/16.jpg';
import image2024b from '../components/img/17.jpg';
import image2024c from '../components/img/33.jpg';
import image2024d from '../components/img/1.jpg';
import image2024e from '../components/img/2.jpg';
import image2024f from '../components/img/5.jpg';


const Gallery = () => {
  // Gallery items for each year
  const gallery2023 = [
    { src: image2023a, alt: '2023 Img 1' },
    { src: image2023b, alt: '2023 Img 2' },
    { src: image2023c, alt: '2023 Img 3' },

  ];


  const gallery2022 = [
    { src: image2022d, alt: '2024 Img 1' },
    { src: image2022b, alt: '2024 Img 2' },
    { src: image2022c, alt: '2024 Img 3' },
    { src: image2022a, alt: '2024 Img 4' },
    { src: image2022e, alt: '2024 Img 5' },
    { src: image2022f, alt: '2024 Img 6' },


  ];


  const gallery2024 = [
    { src: image2024a, alt: '2024 Img 1' },
    { src: image2024b, alt: '2024 Img 2' },
    { src: image2024c, alt: '2024 Img 3' },
    { src: image2024d, alt: '2024 Img 4' },
    { src: image2024e, alt: '2024 Img 5' },
    { src: image2024f, alt: '2024 Img 6' },

  ];
  return (
    <Container className="gallery-container">
      {/* 2023 Gallery */}
      <h2 className="year-heading">2023 Durga Puja</h2>
      <Row>
        {gallery2023.map((item, index) => (
          <Col md={6} lg={4} key={`2023-${index}`} className="mb-4">
            <Card className="gallery-card">
              <Card.Img variant="top" src={item.src} alt={item.alt} className="gallery-img" />
              <Card.Body>
                <Card.Text>{item.alt}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <h2 className="year-heading">2024 Rath Yatra</h2>
      <Row>
        {gallery2024.map((item, index) => (
          <Col md={6} lg={4} key={`2023-${index}`} className="mb-4">
            <Card className="gallery-card">
              <Card.Img variant="top" src={item.src} alt={item.alt} className="gallery-img" />
              <Card.Body>
                <Card.Text>{item.alt}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* 2022 Gallery */}
      <h2 className="year-heading">2024 Ambubachi Narayan Seva</h2>
      <Row>
        {gallery2022.map((item, index) => (
          <Col md={6} lg={4} key={`2022-${index}`} className="mb-4">
            <Card className="gallery-card">
              <Card.Img variant="top" src={item.src} alt={item.alt} className="gallery-img" />
              <Card.Body>
                <Card.Text>{item.alt}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Gallery;
