import React from 'react'

const About = () => {
  return (
    <div><br /><br />
      <h3><center>REST CAMP KALIBARI: A SHORT HISTORY</center></h3>
      <p className='sssa'> The partition of the country on the 15th August 1947, resulted into pouring in of the Railway employees mainly from the erstwhile East Bengal who had opted permanently for Indian Dominion, to the sacred locality under the foot-hills of Nilachal Hill the abode of Godees Kamakhy, Pandu. The uprooted mass, although retained their job, had lost their home and hearth and social identity in the process of the cursed partition.<br />
        The need for these people in those days was, in addition to the job security, to have a dwelling house and once they could get a shelter in the thatched houses constructed by the then Assam Railway authorities, they started feeling the urge to achieve the basic social needs, prime of which was to get an organisation which would cater the religious needs and the cultural social fervour as well. The social bondage among these people grew in no time and with the initiative of a few religious minded and dedicated Rail waymen, the Rest Camp Kalibari was established on the Dipavali day of 1948. The image which was worshiped on that day was housed in a temple made of bamboo structure and walls with hay top which after five years was replaced in 1953 by a semi- pucca temple with cement plastered walls and C.I. sheet roof on wood en structures. The daily Puja were being performed from the day following the Dipavali day of 1948. The year 1948 is al so significant for being the first year of Durga Puja Celebration of the Rest Camp Kalibari. On those days the cultural activities were given a start and pioneered through by the Rest Camp Kalibari Committee till the organisations for the cultural activities came into being.<br />Mentionable that the Bid yamandir School in its infant stage was housed at Kalibari for sometime in early 1948
        Every year, tiff 1988, the image of Goddess Kalt was being replaced by a new image on the day of Vipault Kalipu ja In 1955, while giving Finishing touch and drawing the eyes of the new image to be placed for the next one year, the focal image artist felt a steering jerk and became hesitant to complete the image. A befte grew that the goddess was unhappy at the Frequent replacement of the image. In that particular year, Puja was performed with both the images and the new image was immersed after the Puja retaining the old one. The episode fed the then Kalibari Committee to procure the black stone image of the Goddess Kali along with the Shivalinga from Varanasi in the year 1957 which are still in existence.<br />
        The Durga Puja is per formed every year since 1948 with the exception of 1960 in which year no Durga Puja was held in the Pandu Maligaon locality due to Law and Order problems. In 1972, dusk, to down curfew was in force in the greater Guwahati area during the Puja days and the Puja programmes after dusk, were carried out with a very limited number of volunteers who were provided with cur few passes. The Silver Jubilee of the Rest Camp Kalibari Durga Puja was performed in 1973 with elaborate arrangements and programmes and with great pomp and gaiety and then onwards the Durga Puja of Rest Camp Kalibari is regarded as the biggest festival of the locality. The Silver Jubilee Puja attracted huge visitors not only from the greater Guwahati area but also from the different parts of the NE. Region and North Bengal too.<br />
        The present temple was contructed in 1972-73 and inagurated on 1st November 1973, duly dedicating it to the mankind. Incidentally, the inaguration of the new temple coincided with the Silver Jubilee year of the Kalibari. Gradually, the main Natmandir came up in 1979-80 aand the permanent Durga Mandap in 1984.
        <br />A good number of persons had extended their generous hands and valuable labours as also time for the development of the Kalibari right from the begining whom many are no more in this world. We gratefully remember those revered souls, departed as well as living on this day of adoption of the constitution of the Kalibari.
      </p>


    </div>
  )
}

export default About
